import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import { FaTrashCan } from "react-icons/fa6";
import Filter from "./Filter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Gerer from "./Gerer";
import { deleteRequest, getApiRequest } from "../../apiHandler";
import AlertDialog from "../BiensEstimes/deletePopup";
import { formatDate, formatPrice } from "../../utils/utils";
import { toast } from "react-toastify";
import TrierPars from "./Trerpar";
import {
  avitoLogo,
  yakeeyLogo,
  mubawabLogo,
  saroutyLogo,
  agenzLogo,
  logo as KIlogo,
  marocannonces,
} from "../../component/ConstantImagesURL";
import { selectUser } from "../../Redux/reducer/reducer";
import { useSelector } from "react-redux";

export default function SauvegardesHome() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [wishlistData, setWishlistData] = useState([]);
  const [filterWishlist, setFilterWishlist] = useState();
  const [allCountWishlist, setAllCountWishlist] = useState("");
  const [selectOption, setSelectOption] = useState("annonce récente");
  const [selectedStatus, setSelectedStatus] = useState("Toutes");
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector(selectUser);
  const [removeWishlistId, setRemoveWishlistId] = useState("");
  const itemsPerPage = 6;

  const opens = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getWishlist = async (updateValue) => {
    const getWishlistRes = await getApiRequest("/api/get-annonces-wishlist");
    if (getWishlistRes?.status === 200) {
      setAllCountWishlist(getWishlistRes?.response?.length);
      const parsedWishlistData = getWishlistRes?.response?.map((item) => {
        const parsedAdsData = JSON.parse(item.adsdata);
        return { ...item, adsdata: parsedAdsData };
      });

      const sortResultCards = [...parsedWishlistData]?.sort((a, b) => {
        switch (updateValue?.sortOption) {
          case "annonce récente":
            return new Date(b.last_modified) - new Date(a.last_modified);
          case "annonce ancienne":
            return new Date(a.last_modified) - new Date(b.last_modified);
          case "prix croissant":
            return a.adsdata.price - b.adsdata.price;
          case "prix décroissant":
            return b.adsdata.price - a.adsdata.price;
          case "surface croissante":
            return a.adsdata.area - b.adsdata.area;
          case "surface décroissante":
            return b.adsdata.area - a.adsdata.area;
          default:
            return new Date(b.last_modified) - new Date(a.last_modified);
        }
      });
      setWishlistData(sortResultCards);
      setFilterWishlist(sortResultCards);
      setCurrentPage(1);
    } else {
      toast.error(getWishlistRes?.message);
    }
  };

  useEffect(() => {
    getWishlist();
  }, []);

  const deleteDataApi = async () => {
    if (deleteId) {
      const deleteRes = await deleteRequest(
        `/api/delete-annonce-wishlist/${deleteId}`
      );
      if (deleteRes?.status === 200) {
        toast.success(deleteRes?.message);
        setOpen(false);
        getWishlist();
        setSelectedStatus("Toutes");
      } else {
        toast.error(deleteRes?.message);
      }
    }
  };

  const handleDeleteOpen = (id, check = false) => {
    setOpen(true);
    setRemoveWishlistId(check);
    setDeleteId(id);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = wishlistData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(wishlistData.length / itemsPerPage);

  // handle page change
  const redirectOnAdsDetail = (adsId) => {
    const adsDetailsLink = `${process.env.REACT_APP_WEB_URL}/annonce-detail/${adsId}`;
    window.open(adsDetailsLink);
  };

  const websiteLogos = {
    "avito.ma": avitoLogo,
    "yakeey.com": yakeeyLogo,
    "mubawab.ma": mubawabLogo,
    "sarouty.ma": saroutyLogo,
    "agenz.ma": agenzLogo,
    "ki.ma": KIlogo,
    "marocannonces.com": marocannonces,
  };

  const isWishlist = async () => {
    try {
      await getApiRequest(`/api/annonce/wishlist/${deleteId}/${user?._id}`);
      setOpen(false);
      getWishlist();
    } catch (err) {
      console.log("isWishlist", err);
    }
  };

  return (
    <Layout>
      <Box>
        <Paper
          elevation={3}
          sx={{ p: { xs: 2, sm: 4 }, width: "100%", borderRadius: "13px" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Annonces sauvegardées ({allCountWishlist})
            </Typography>
            <a href={`${process.env.REACT_APP_WEB_URL}/annonces`}>
              <Button
                variant="contained"
                color="primary"
                sx={{ px: 2, width: { xs: "100%", sm: "auto" } }}
              >
                nouvelle recherche
              </Button>
            </a>
          </Stack>
          <Box className="myBtns" sx={{ mt: 2, display: { md: "block" } }}>
            <Filter
              wishlistData={wishlistData}
              filterWishlist={filterWishlist}
              setWishlistData={setWishlistData}
              allCountWishlist={allCountWishlist}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              resetPagination={() => setCurrentPage(1)}
            />
          </Box>
          <Stack
            direction="row"
            mt={1}
            mb={0.5}
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <TrierPars
              selectOption={selectOption}
              setSelectOption={setSelectOption}
              wishlistData={wishlistData}
              getWishlist={getWishlist}
              setSelectedStatus={setSelectedStatus}
              resetPagination={() => setCurrentPage(1)}
            />
            <Menu
              id="basic-menu"
              sx={{ mt: 1 }}
              anchorEl={anchorEl}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              opens={opens}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Annoncerécente"
                      control={<Radio />}
                      label="Annonce récente"
                    />
                    <FormControlLabel
                      value="maAnnonceanciennele"
                      control={<Radio />}
                      label="Annonce ancienne"
                    />
                    <FormControlLabel
                      value="Prixcroissant"
                      control={<Radio />}
                      label="Prix croissant"
                    />
                    <FormControlLabel
                      value="Prixdecroissant"
                      control={<Radio />}
                      label=" Prix décroissant"
                    />
                    <FormControlLabel
                      value="Surfacecroissante"
                      control={<Radio />}
                      label="Surface croissante"
                    />
                    <FormControlLabel
                      value="Surfacedecroissante"
                      control={<Radio />}
                      label="Surface décroissante"
                    />
                  </RadioGroup>
                </FormControl>
              </MenuItem>
            </Menu>
          </Stack>
          <Grid container rowSpacing={3} columnSpacing={3}>
            {currentItems &&
              currentItems?.map((item) => {
                const adsData = item.adsdata;
                const {
                  property_type,
                  rooms,
                  area,
                  address,
                  city,
                  original_price,
                  website,
                  url,
                  s3_images_url,
                  images_url,
                  id,
                } = adsData || {};

                const logo = websiteLogos[website] || KIlogo;
                const imagesUrl = s3_images_url
                  ? s3_images_url.map(
                      (image) => `https://content.1ki.ma/${image}`
                    )
                  : images_url;

                return (
                  <Grid key={item._id} item xs={12} sm={6} md={4}>
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        maxHeight: "480px",
                        minHeight: "480px",
                      }}
                    >
                      <Stack
                        direction="row"
                        mb="8px"
                        alignItems="center"
                        spacing={1}
                      >
                        <Avatar
                          alt="annonces Web logo"
                          src={logo}
                          sx={{
                            width: "100px",
                            height: "40px",
                            maxHeight: "45px",
                            borderRadius: "0px",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => redirectOnAdsDetail(item?.annonce_id)}
                        />
                      </Stack>
                      <Box
                        onClick={() => redirectOnAdsDetail(item?.annonce_id)}
                        component="img"
                        src={imagesUrl[0]}
                        alt=""
                        sx={{
                          borderRadius: "10px",
                          my: 1,
                          cursor: "pointer",
                          width: "100% ",
                          minHeight: "218px",
                          maxHeight: "218px",
                          objectFit: "cover",
                        }}
                      />
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ cursor: "pointer" }}
                      >
                        <Box sx={{ mt: 2, width: "100%" }}>
                          <Stack
                            direction="row"
                            alignItems="start"
                            justifyContent="space-between"
                            gap={"5px"}
                          >
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              alignItems="center"
                            >
                              <Typography
                                variant="h6"
                                className="fontMontserrat"
                                sx={{ height: "55px" }}
                                onClick={() =>
                                  redirectOnAdsDetail(item?.annonce_id)
                                }
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {property_type}
                                </span>
                                {rooms != null
                                  ? rooms > 1
                                    ? " - " + rooms + " Pièces"
                                    : " - " + rooms + " Pièce"
                                  : ""}
                                {area == null ? "" : " - " + area + "m²"}
                              </Typography>
                            </Stack>
                            <FavoriteIcon
                              size={24}
                              cursor="pointer"
                              style={{
                                color: "red",
                              }}
                              onClick={() => handleDeleteOpen(id, true)}
                            />
                          </Stack>
                          <Typography
                            variant="subtitle1"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {address}
                            {city}
                          </Typography>
                          <Typography variant="h5" color="primary.main">
                            {original_price === null
                              ? 0 + " DH"
                              : formatPrice(parseInt(original_price))}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={{ mt: 1, mb: 0.7 }}
                          >
                            Professionnel
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ lineHeight: "15px" }}
                          >
                            {adsData?.agency_name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "300" }}
                          >
                            Le {formatDate(item.created_at).dateDMY}
                          </Typography>
                        </Box>
                      </Stack>
                    </Paper>
                    <Stack
                      direction="row"
                      spacing={{ xs: 0.1, sm: 2, md: 2 }}
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ mt: 1.5 }}
                    >
                      <Gerer
                        item={item}
                        wishlistData={wishlistData}
                        setWishlistData={setWishlistData}
                        getWishlist={getWishlist}
                        selectedStatus={selectedStatus}
                        setSelectedStatus={setSelectedStatus}
                      />
                      <Button
                        variant="outlined"
                        startIcon={
                          <FaTrashCan style={{ fontSize: "0.9rem" }} />
                        }
                        component={Link}
                        to="#"
                        sx={{ px: { xs: 3, sm: 5 } }}
                        onClick={() => handleDeleteOpen(item._id)}
                      >
                        Supprimer
                      </Button>
                    </Stack>
                  </Grid>
                );
              })}
          </Grid>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ mt: 4 }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </Paper>
      </Box>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        deleteDataApi={removeWishlistId ? isWishlist : deleteDataApi}
        check={
          removeWishlistId &&
          "Etes-vous sûr de vouloir supprimer ceci des éléments de la liste de souhaits ?"
        }
      />
    </Layout>
  );
}
