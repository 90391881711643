import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { postApiRequest } from "./../apiHandler/index";

export const withUserTracking = (WrappedComponent) => {
  return function WithUserTracking(props) {
    const location = useLocation();

    useEffect(() => {
      const logUserActivity = async (action, details) => {
        const data = {
          url: process.env.REACT_APP_BUYER_SELLER_WEB_URL,
          action,
          details,
          page: location.pathname,
        };
        console.log(`data: ${JSON.stringify(data)}`);
        try {
          await postApiRequest("/api/log-user-activity", data);
        } catch (err) {
          console.error("Failed to log user activity", err);
        }
      };
      // Define handlers and observer once
      const handleUserClick = (event) => {
        logUserActivity("click", { message: event.target.textContent });
      };

      const handleUserKeypress = (event) => {
        logUserActivity("keypress", { key: event.key });
      };

      const handleUserSelection = () => {
        const selection = window.getSelection().toString();
        if (selection) {
          logUserActivity("selection", { text: selection });
        }
      };

      const handleFormInputChange = (event) => {
        logUserActivity("input-change", { value: event.target.value });
      };

      const handleInputBlur = (event) => {
        logUserActivity("input-final-value", { value: event.target.value });
      };

      const handleMutation = (mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "value"
          ) {
            const target = mutation.target;
            logUserActivity("input-final-value", { value: target.value });
          }
        });
      };

      // Attach event listeners
      window.addEventListener("click", handleUserClick);
      window.addEventListener("keypress", handleUserKeypress);
      document.addEventListener("selectionchange", handleUserSelection);

      // Attach listeners to form elements
      const inputs = document.querySelectorAll("input, textarea, select");
      inputs.forEach((element) => {
        element.addEventListener("input", handleFormInputChange);
        element.addEventListener("blur", handleInputBlur);

        // Observe changes to input values programmatically
        const observer = new MutationObserver(handleMutation);
        observer.observe(element, {
          attributes: true,
          attributeFilter: ["value"],
        });

        // Clean up mutation observer
        return () => {
          observer.disconnect();
        };
      });

      // Log page navigation
      logUserActivity("navigation", { to: location.pathname });

      // Cleanup listeners on unmount
      return () => {
        window.removeEventListener("click", handleUserClick);
        window.removeEventListener("keypress", handleUserKeypress);
        document.removeEventListener("selectionchange", handleUserSelection);

        inputs.forEach((element) => {
          element.removeEventListener("input", handleFormInputChange);
          element.removeEventListener("blur", handleInputBlur);
        });
      };
    }, [location]);

    return <WrappedComponent {...props} />;
  };
};
