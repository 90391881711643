import React, { useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { ReactComponent as GererIcon } from "./gerer.svg";
import { putRequest } from "../../apiHandler";

export default function Gerer({
  item,
  getWishlist,
  selectedStatus,
  setSelectedStatus,
}) {
  const [wishlistId, setWishlistId] = useState();
  const [wishlist, setWishlist] = useState();
  const [open, setOpen] = useState(false);
  const GererClickOpen = (id) => {
    setWishlistId(id);
    setWishlist(selectedStatus);
    setOpen(true);
  };
  const GererhandleClose = () => {
    setOpen(false);
  };

  const editGetWishlist = (event) => {
    const { value } = event.target;
    setWishlist(value);
  };

  const updateWishlist = async () => {
    if (wishlistId) {
      const updateRes = await putRequest(
        `/api/update-annonce-wishlist-status/${wishlistId}`,
        { status: wishlist }
      );
      if (updateRes?.status === 200) {
        toast.success(updateRes?.message);
        setOpen(false);
        getWishlist();
        setSelectedStatus("Toutes");
      } else {
        toast.error(updateRes?.message);
      }
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          GererClickOpen(item._id);
        }}
        variant="outlined"
        color="primary"
        size="small"
        startIcon={
          <GererIcon style={{ fontSize: "1.2rem", color: "inherit" }} />
        }
        component={Link}
        to="#"
        sx={{ px: { xs: 3, sm: 5 } }}
      >
        Gérer
      </Button>

      <Dialog
        onClose={GererhandleClose}
        aria-labelledby="CONTACT"
        open={open}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
          "& .MuiPaper-root": {
            margin: 0.5,
            width: "98%",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            px: { xs: 2, sm: 4 },
            py: { xs: 3, sm: 5 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: 0.5, sm: 1.8 },
            background: "#d9d9d92b",
            border: "none",
            fontSize: { sm: "h6", md: "1.4rem !important" },
            fontWeight: "900",
            color: "#4d4a4a",
          }}
          id="CONTACT"
        >
          <GererIcon width={28} height={28} style={{ color: "#0066ee" }} />
          Gérer l'annonce
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={GererhandleClose}
          sx={{
            position: "absolute",
            right: { xs: 0, sm: 15 },
            top: { xs: 0, sm: 46 },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoMdClose />
        </IconButton>
        <DialogContent>
          <Box component="form" sx={{ px: { xs: 0, sm: 4 } }}>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="status"
                sx={{ width: "100%" }}
                onChange={editGetWishlist}
                value={open ? wishlist : selectedStatus}
              >
                <FormControlLabel
                  fullWidth
                  sx={{ width: "100%", justifyContent: "space-between" }}
                  value="À contacter"
                  control={<Radio />}
                  label="À contacter"
                  labelPlacement="start"
                />
                <FormControlLabel
                  fullWidth
                  sx={{ width: "100%", justifyContent: "space-between" }}
                  value="Contacté"
                  control={<Radio />}
                  label="Contacté"
                  labelPlacement="start"
                />
                <FormControlLabel
                  fullWidth
                  sx={{ width: "100%", justifyContent: "space-between" }}
                  value="À visiter"
                  control={<Radio />}
                  label="À visiter"
                  labelPlacement="start"
                />
                <FormControlLabel
                  fullWidth
                  sx={{ width: "100%", justifyContent: "space-between" }}
                  value="Visité"
                  control={<Radio />}
                  label="Visité"
                  labelPlacement="start"
                />
                <FormControlLabel
                  fullWidth
                  sx={{ width: "100%", justifyContent: "space-between" }}
                  value="Dossierdpose"
                  control={<Radio />}
                  label="Dossier déposé"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Divider sx={{ my: 4 }} />
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Button
              variant="contained"
              sx={{ px: 2 }}
              onClick={() => {
                updateWishlist();
              }}
            >
              Enregistrer
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
